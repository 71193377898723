import React, { Component } from 'react'
import { Provider, useDispatch } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from './Pages/Auth/Login'
import './scss/style.scss'
import store from './store'
import LocalStorageService from 'src/Service/LocalStoage'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AutoLogoutTimer from './AutoLogoutTimer'
import Maintenance from './Maintenance'
const user_info_json = localStorage.getItem('user_info')
const user_info = JSON.parse(user_info_json)
export const APIURL = 'https://logiprouat.nagamills.com/LP_API/api/v1/'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

function App() {
  const token = LocalStorageService.getLocalstorage('auth_token')
  let isauth = token ? true : false
  // console.log('token : ' + token)

  const maintenance = false;

  return (
    <React.Suspense fallback={loading}>
      <ToastContainer />
      {maintenance ? (
        <Maintenance />
      ) : ( 
        <>
          {isauth ? <AutoLogoutTimer timeoutInMinutes={30}/>: <Login /> }
          {isauth ? <DefaultLayout /> :loading }
        </>
      )}
      {/* {isauth ?<AutoLogoutTimer timeoutInMinutes={30}/>: <Login /> }
      {isauth ? <DefaultLayout /> :loading } */}



    </React.Suspense>
  )
}

export default App
